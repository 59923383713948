const fable = {
  name: 'Testimonials',
  variations: [
    {
      title: 'Default',
      props: {
        data: {
          primary: {
            title: {
              html: '<h2><strong>Reviews</strong> from some of our recent clients</h2>',
              text: 'Reviews from some of our recent clients',
            },
            use_global_testimonials: 'No',
          },
          items: [
            {
              image: {
                url:
                  'https://images.prismic.io/youbroker/97cf05f0-a99f-4700-b2d9-9e26b64247ab_family.png?auto=compress%2Cformat',
              },
              name: {
                html: '<h5>Jess, Greater Melbourne</h5>',
                text: 'Jess, Greater Melbourne',
              },
              testimonial: {
                html:
                  '<p>I found Lendi online wanting to compare my home loan rate and I’m so glad I did. Alex from Lendi called me after I did a comparison and we had an honest chat about my family’s wants and needs from a home loan. The whole process wa ...</p>',
                text:
                  'I found Lendi online wanting to compare my home loan rate and I’m so glad I did. Alex from Lendi called me after I did a comparison and we had an honest chat about my family’s wants and needs from a home loan. The whole process wa ...',
              },
            },
            {
              image: {
                url:
                  'https://images.prismic.io/youbroker/439105dc-afc4-469b-81d3-70dd3883428b_couple-3.png?auto=compress%2Cformat',
              },
              name: {
                html: '<h5>Elli, Greater Melbourne</h5>',
                text: 'Elli, Greater Melbourne',
              },
              testimonial: {
                html:
                  '<p>I found Lendi online wanting to compare my home loan rate and I’m so glad I did. Alex from Lendi called me after I did a comparison and we had an honest chat about my family’s wants and needs from a home loan. The whole process wa ...</p>',
                text:
                  'I found Lendi online wanting to compare my home loan rate and I’m so glad I did. Alex from Lendi called me after I did a comparison and we had an honest chat about my family’s wants and needs from a home loan. The whole process wa ...',
              },
            },
          ],
        },
      },
    },
  ],
};

export default fable;
