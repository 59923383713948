const fable = {
  name: 'Features Grid',
  variations: [
    {
      title: 'Default',
      props: {
        data: {
          items: [
            {
              content: {
                html:
                  '<p>No waiting for a random broker or website to send you paper forms or to a call centre.<br />Use our digital tools to apply right away.</p>',
                text:
                  'No waiting for a random broker or website to send you paper forms or to a call centre.\nUse our digital tools to apply right away.',
              },
              icon: {
                url:
                  'https://youbroker.cdn.prismic.io/youbroker/257dba9d-3ee5-4d71-b1f6-8b9d0f65d217_icons8-running_rabbit.svg',
              },
              title: {
                text: 'Faster',
                html: '<p>Faster</p>',
              },
            },
            {
              content: {
                html:
                  '<p>Drive your loan like a broker.  <br />Make better decisions on your loan with clear, personalised comparisons from multiple lenders</p>',
                text:
                  'Drive your loan like a broker.  \nMake better decisions on your loan with clear, personalised comparisons from multiple lenders',
              },
              icon: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/2983ed67-ea96-452c-a9ab-923cc6b9fc51_icons8-view.svg',
              },
              title: {
                text: 'Clearer',
                html: '<p>Clearer</p>',
              },
            },
            {
              content: {
                html:
                  '<p>Track your lean online in our digital portal. No more back and forwards on email and paperwork.</p>',
                text: 'Track your lean online in our digital portal. No more back and forwards on email and paperwork.',
              },
              icon: {
                url:
                  'https://youbroker.cdn.prismic.io/youbroker/9fe9d56a-fc70-4f92-b099-c97bfac23449_icons8-advance.svg',
              },
              title: {
                text: 'Smoother',
                html: '<p>Smoother</p>',
              },
            },
            {
              content: {
                html: '<p>Our team has your back and works for you, not the lender.</p>',
                text: 'Our team has your back and works for you, not the lender.',
              },
              icon: {
                url:
                  'https://youbroker.cdn.prismic.io/youbroker/7a1f8e4e-4147-46b2-a335-1048c2f500a6_icons8-boastful.svg',
              },
              title: {
                text: 'You First',
                html: '<p>You First</p>',
              },
            },
          ],
        },
      },
    },
  ],
};

export default fable;
