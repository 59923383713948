const fable = {
  name: 'Breakout Box',
  variations: [
    {
      title: 'Default',
      props: {
        data: {
          primary: {
            breakout_content: {
              html:
                '<p>Whether you’ve just bought your first home or you’ve lived in your home for a while now, sometimes getting into the routine of things means you miss out on feeling cosy in your own space. </p>',
              text:
                'Whether you’ve just bought your first home or you’ve lived in your home for a while now, sometimes getting into the routine of things means you miss out on feeling cosy in your own space. ',
            },
            breakout_cta_link: {
              url: '/fable/',
            },
            breakout_cta_text: {
              html: '<p>Learn more</p>',
              text: 'Learn more',
            },
            breakout_title: {
              html: '<h1>Refinancing?</h1>',
              text: 'Refinancing?',
            },
          },
        },
      },
    },
  ],
};

export default fable;
