const fable = {
  name: 'Image',
  variations: [
    {
      title: 'Default',
      props: {
        data: {
          primary: {
            image: {
              url:
                'https://images.prismic.io/youbroker/7b235b33-e6ab-43d2-9562-02809133a858_couple-2.png?auto=compress%2Cformat&rect=0%2C0%2C1306%2C1052&w=2000&h=1611',
            },
          },
        },
      },
    },
  ],
};

export default fable;
