const fable = {
  name: 'Contact Form Block',
  variations: [
    {
      title: 'Default',
      props: {
        data: {
          primary: {
            button_text: {
              html: '<h5>Submit</h5>',
              text: 'Submit',
            },
            content: {
              html: '<p>Send us an expression of interest!</p>',
              text: 'Send us an expression of interest!',
            },
            title: {
              html: '<h2>Help us set people free from the stuffiness and slowness of mortgages!</h2>',
              text: 'Help us set people free from the stuffiness and slowness of mortgages!',
            },
          },
        },
      },
    },
  ],
};

export default fable;
