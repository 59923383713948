const fable = {
  name: 'Partners',
  variations: [
    {
      title: 'Default',
      props: {
        data: {
          primary: {
            title: {
              html: '<h1>We help people find and apply for loans that suit their particular circumstances.</h1>',
              text: 'We help people find and apply for loans that suit their particular circumstances.',
            },
          },
          items: [
            {
              logo: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/6ac0b3cc-e66b-4577-9c9c-660a18eb3bcb_firefighters.svg',
              },
            },
            {
              logo: {
                url:
                  'https://youbroker.cdn.prismic.io/youbroker/3e73494f-bf5f-40bd-850b-e0fae354f704_mortgage-house.svg',
              },
            },
            {
              logo: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/c76daa5b-484d-41ca-8af4-7fd663550905_pepper-money.svg',
              },
            },
            {
              logo: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/c3f5afef-b88b-4a26-a840-11c7142f143c_blue-stone.svg',
              },
            },
            {
              logo: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/84c3fef2-e115-46a3-a415-bc16a1f123de_liberty.svg',
              },
            },
            {
              logo: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/67f79a0e-ec41-4aaa-8595-6d47bfe51d02_unibank.svg',
              },
            },
            {
              logo: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/b38c9afb-1cd9-4ea3-8579-46a450171cad_mas.svg',
              },
            },
            {
              logo: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/9f270a19-c580-4261-aefa-fd0971d3bc6e_nab.svg',
              },
            },
            {
              logo: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/b1f9ca4c-9421-496e-9a80-35a5b31223b8_commbank.svg',
              },
            },
            {
              logo: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/73d5e646-42ff-406f-ac37-5f2a9f14d23e_citi.svg',
              },
            },
            {
              logo: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/ee7936e7-b59a-410e-9b5a-3f4cf36bd487_hpb.svg',
              },
            },
            {
              logo: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/39c1aeb9-23a3-4aca-a116-a0bd37389834_cua.svg',
              },
            },
            {
              logo: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/e9a5b9e6-c005-4d70-80aa-680ead03639a_westpac.svg',
              },
            },
            {
              logo: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/6209a538-8b6c-475c-872c-8a82a0489c2c_anz.svg',
              },
            },
            {
              logo: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/1c0acdd2-47c1-4733-8eae-81fec63b9b6e_qudos.svg',
              },
            },
          ],
        },
      },
    },
    {
      title: 'Logos only',
      props: {
        data: {
          primary: {},
          items: [
            {
              logo: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/6ac0b3cc-e66b-4577-9c9c-660a18eb3bcb_firefighters.svg',
              },
            },
            {
              logo: {
                url:
                  'https://youbroker.cdn.prismic.io/youbroker/3e73494f-bf5f-40bd-850b-e0fae354f704_mortgage-house.svg',
              },
            },
            {
              logo: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/c76daa5b-484d-41ca-8af4-7fd663550905_pepper-money.svg',
              },
            },
            {
              logo: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/c3f5afef-b88b-4a26-a840-11c7142f143c_blue-stone.svg',
              },
            },
            {
              logo: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/84c3fef2-e115-46a3-a415-bc16a1f123de_liberty.svg',
              },
            },
            {
              logo: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/67f79a0e-ec41-4aaa-8595-6d47bfe51d02_unibank.svg',
              },
            },
            {
              logo: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/b38c9afb-1cd9-4ea3-8579-46a450171cad_mas.svg',
              },
            },
            {
              logo: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/9f270a19-c580-4261-aefa-fd0971d3bc6e_nab.svg',
              },
            },
            {
              logo: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/b1f9ca4c-9421-496e-9a80-35a5b31223b8_commbank.svg',
              },
            },
            {
              logo: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/73d5e646-42ff-406f-ac37-5f2a9f14d23e_citi.svg',
              },
            },
            {
              logo: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/ee7936e7-b59a-410e-9b5a-3f4cf36bd487_hpb.svg',
              },
            },
            {
              logo: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/39c1aeb9-23a3-4aca-a116-a0bd37389834_cua.svg',
              },
            },
            {
              logo: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/e9a5b9e6-c005-4d70-80aa-680ead03639a_westpac.svg',
              },
            },
            {
              logo: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/6209a538-8b6c-475c-872c-8a82a0489c2c_anz.svg',
              },
            },
            {
              logo: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/1c0acdd2-47c1-4733-8eae-81fec63b9b6e_qudos.svg',
              },
            },
          ],
        },
      },
    },
  ],
};

export default fable;
