const fable = {
  name: 'Blockquote',
  variations: [
    {
      title: 'Default',
      props: {
        data: {
          primary: {
            quote: {
              html:
                '<p>&quot;Whether you’ve just bought your first home or you’ve lived in your home for a while now, sometimes getting into the routine of things means you miss out on feeling cosy in your own space. Here are some things that can change your home entirely by bringing warmth and sparking joy.&quot;</p>',
              text:
                '"Whether you’ve just bought your first home or you’ve lived in your home for a while now, sometimes getting into the routine of things means you miss out on feeling cosy in your own space. Here are some things that can change your home entirely by bringing warmth and sparking joy."',
            },
            credit: {
              html: '<h6>Jane McDonald</h6>',
              text: 'Jane McDonald',
            },
          },
        },
      },
    },
  ],
};

export default fable;
