const fable = {
  name: 'Two Cols Header',
  variations: [
    {
      title: 'With Loan Card',
      props: {
        data: {
          primary: {
            add_loan_card: true,
            content: {
              html: '<p>Tired of home loan hassles? Compare, save and settle faster online with YouBroker</p>',
              text: 'Tired of home loan hassles? Compare, save and settle faster online with YouBroker',
            },
            image: {
              url:
                'https://images.prismic.io/youbroker/c45a5b34-a517-47f5-9e86-59fa74cf4d8c_photo-1603792582751-c73976204e1e.jpeg?auto=compress%2Cformat&rect=0%2C0%2C1834%2C2750&w=2000&h=2999',
            },
            lead_flow_field: true,
            primary_title: {
              html: '<h1>Buying a house?</h1>',
              text: 'Buying a house?',
            },
            secondary_title: {
              html: '<h1>Be your own broker</h1>',
              text: 'Be your own broker',
            },
          },
        },
      },
    },
    {
      title: 'Without Loan Card',
      props: {
        data: {
          primary: {
            add_loan_card: false,
            content: {
              html: '<p>Tired of home loan hassles? Compare, save and settle faster online with YouBroker</p>',
              text: 'Tired of home loan hassles? Compare, save and settle faster online with YouBroker',
            },
            image: {
              url:
                'http://localhost:8000/static/9e816509f50bfc94b0a505659cd3f79f/0b6c6/d3068a67-a884-492d-8ae4-433f378f1a9f_bruno-emmanuelle-azsk_6IMT3I-unsplash.jpg',
            },
            lead_flow_field: true,
            primary_title: {
              html: '<h1>Buying a house?</h1>',
              text: 'Buying a house?',
            },
            secondary_title: {
              html: '<h1>Be your own broker</h1>',
              text: 'Be your own broker',
            },
          },
        },
      },
    },
    {
      title: 'Compact',
      props: {
        data: {
          primary: {
            add_loan_card: false,
            content: {
              html: null,
              text: null,
            },
            image: {
              url: null,
            },
            lead_flow_field: true,
            primary_title: {
              html: '<h1>Be your own broker</h1>',
              text: 'Be your own broker',
            },
          },
        },
      },
    },
  ],
};

export default fable;
