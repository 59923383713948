const fable = {
  name: 'Title',
  variations: [
    {
      title: 'Default',
      props: {
        data: {
          primary: {
            colour: '',
            title: {
              html: '<h1>Buying a house?</h1>',
              text: 'Buying a house?',
            },
          },
        },
      },
    },
  ],
};

export default fable;
