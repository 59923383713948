const fable = {
  name: 'Email Capture',
  variations: [
    {
      title: 'Default',
      props: {
        data: {
          primary: {
            description: {
              html:
                '<p>Our experts will help you research, shortlist and apply for your home loan online. Chat to one of our Home Loan Coaches at a time that suits you.</p>',
              text:
                'Our experts will help you research, shortlist and apply for your home loan online. Chat to one of our Home Loan Coaches at a time that suits you.',
            },
            submit_text: {
              html: '<p>Get in there</p>',
              text: 'Get in there',
            },
            title: {
              html: '<h1>Become your own broker</h1>',
              text: 'Become your own broker',
            },
          },
        },
      },
    },
  ],
};

export default fable;
