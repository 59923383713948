const fable = {
  name: 'Two Cols Steps Image',
  variations: [
    {
      title: 'Default',
      props: {
        data: {
          primary: {
            image: {
              url:
                'https://images.prismic.io/youbroker/7b235b33-e6ab-43d2-9562-02809133a858_couple-2.png?auto=compress%2Cformat&rect=0%2C0%2C1306%2C1052&w=2000&h=1611',
            },
            title: {
              html: '<h1>The Youbroker Process in 3 Easy Steps</h1>',
              text: 'The Youbroker Process in 3 Easy Steps',
            },
          },
          items: [
            {
              step_content: {
                html: '<p>Easily compare products from over 40 lenders on our web platform!</p>',
                text: 'Easily compare products from over 40 lenders on our web platform!',
              },
              step_title: {
                html: '<p>Explore your options.</p>',
                text: 'Explore your options.',
              },
            },
            {
              step_content: {
                html: '<p>We&#39;re here to help you make the right choice. Remember, our service is free.</p>',
                text: "We're here to help you make the right choice. Remember, our service is free.",
              },
              step_title: {
                html: '<p>Identify the right loan</p>',
                text: 'Identify the right loan',
              },
            },
            {
              step_content: {
                html: '<p>We will lodge and manage your application through until settlement.</p>',
                text: 'We will lodge and manage your application through until settlement.',
              },
              step_title: {
                html: '<p>Prepare and lodge</p>',
                text: 'Prepare and lodge',
              },
            },
          ],
        },
      },
    },
  ],
};

export default fable;
