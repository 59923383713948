const fable = {
  name: 'Two Cols Accordion',
  variations: [
    {
      title: 'Image Left',
      props: {
        data: {
          primary: {
            content: {
              html: '<p>Get qualified in minutes. YouBroker makes it fast and easy for your mortgage pre-approval.</p>',
              text: 'Get qualified in minutes. YouBroker makes it fast and easy for your mortgage pre-approval.',
            },
            cta_link: {
              url: '/undefined/',
            },
            cta_text: {
              html: '<p>Get Started</p>',
              text: 'Get Started',
            },
            image: {
              url: null,
            },
            image_left: true,
            title: {
              html: '<h4>How does YouBroker work?</h4>',
              text: 'How does YouBroker work?',
            },
          },
          items: [
            {
              content: {
                html:
                  '<p>See the latest mortgage rates and the features of home loans you&#39;re interested in with our simple, user-friendly comparison calculator.</p>',
                text:
                  "See the latest mortgage rates and the features of home loans you're interested in with our simple, user-friendly comparison calculator.",
              },
              icon: {
                url:
                  'https://youbroker.cdn.prismic.io/youbroker/9051dac3-6b69-4d74-ad61-ae241bb8b2d5_icons8-conversion.svg',
              },
              image: {
                url:
                  'https://images.prismic.io/youbroker/ef9edefa-ac5e-4847-bda2-51ecf319abaf_compare-new.png?auto=compress%2Cformat&rect=0%2C0%2C2308%2C2032&w=2000&h=1761',
              },
              title: {
                html: '<p>Compare and filter</p>',
                text: 'Compare and filter',
              },
            },
            {
              content: {
                html:
                  '<p>Pick your preferred loans and see if you qualify. Our expert team validates your choice and borrowing</p>',
                text:
                  'Pick your preferred loans and see if you qualify. Our expert team validates your choice and borrowing',
              },
              icon: {
                url:
                  'https://youbroker.cdn.prismic.io/youbroker/7f836b19-a5c8-4669-b820-8484e71fb289_icons8-wish_list.svg',
              },
              image: {
                url:
                  'https://images.prismic.io/youbroker/3177d197-687d-4e07-83b2-e9be251282f6_shortlist-new.png?auto=compress%2Cformat&rect=0%2C0%2C2308%2C2032&w=2000&h=1761',
              },
              title: {
                html: '<p>Shortlist and Qualify</p>',
                text: 'Shortlist and Qualify',
              },
            },
            {
              content: {
                html: '<p>Apply on our digital portal. Our team manages approval with the lender</p>',
                text: 'Apply on our digital portal. Our team manages approval with the lender',
              },
              icon: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/2ef6cb25-a2c1-468d-9f6b-11ac48c5ece9_icons8-cv.svg',
              },
              image: {
                url:
                  'https://images.prismic.io/youbroker/4930f0bd-6422-43ac-9b52-59d7dcb181c9_apply-new.png?auto=compress%2Cformat&rect=0%2C0%2C2308%2C2032&w=2000&h=1761',
              },
              title: {
                html: '<p>Easily apply</p>',
                text: 'Easily apply',
              },
            },
            {
              content: {
                html: '<p>Track the completion and settlement of your loan online from start to finish</p>',
                text: 'Track the completion and settlement of your loan online from start to finish',
              },
              icon: {
                url:
                  'https://youbroker.cdn.prismic.io/youbroker/090c8cc5-15de-4cfe-bd2a-6019df8961c8_icons8-clock_checked.svg',
              },
              image: {
                url:
                  'https://images.prismic.io/youbroker/40082d6b-b8cd-424c-80f1-de3bfb9193df_choose-new.png?auto=compress%2Cformat&rect=0%2C0%2C2308%2C2032&w=2000&h=1761',
              },
              title: {
                html: '<p>Track and Settle</p>',
                text: 'Track and Settle',
              },
            },
          ],
        },
      },
    },
    {
      title: 'Image Right',
      props: {
        data: {
          primary: {
            content: {
              html: '<p>Get qualified in minutes. YouBroker makes it fast and easy for your mortgage pre-approval.</p>',
              text: 'Get qualified in minutes. YouBroker makes it fast and easy for your mortgage pre-approval.',
            },
            cta_link: {
              url: '/undefined/',
            },
            cta_text: {
              html: '<p>Get Started</p>',
              text: 'Get Started',
            },
            image: {
              url: null,
            },
            image_left: false,
            title: {
              html: '<h4>How does YouBroker work?</h4>',
              text: 'How does YouBroker work?',
            },
          },
          items: [
            {
              content: {
                html:
                  '<p>See the latest mortgage rates and the features of home loans you&#39;re interested in with our simple, user-friendly comparison calculator.</p>',
                text:
                  "See the latest mortgage rates and the features of home loans you're interested in with our simple, user-friendly comparison calculator.",
              },
              icon: {
                url:
                  'https://youbroker.cdn.prismic.io/youbroker/9051dac3-6b69-4d74-ad61-ae241bb8b2d5_icons8-conversion.svg',
              },
              image: {
                url:
                  'https://images.prismic.io/youbroker/ef9edefa-ac5e-4847-bda2-51ecf319abaf_compare-new.png?auto=compress%2Cformat&rect=0%2C0%2C2308%2C2032&w=2000&h=1761',
              },
              title: {
                html: '<p>Compare and filter</p>',
                text: 'Compare and filter',
              },
            },
            {
              content: {
                html:
                  '<p>Pick your preferred loans and see if you qualify. Our expert team validates your choice and borrowing</p>',
                text:
                  'Pick your preferred loans and see if you qualify. Our expert team validates your choice and borrowing',
              },
              icon: {
                url:
                  'https://youbroker.cdn.prismic.io/youbroker/7f836b19-a5c8-4669-b820-8484e71fb289_icons8-wish_list.svg',
              },
              image: {
                url:
                  'https://images.prismic.io/youbroker/3177d197-687d-4e07-83b2-e9be251282f6_shortlist-new.png?auto=compress%2Cformat&rect=0%2C0%2C2308%2C2032&w=2000&h=1761',
              },
              title: {
                html: '<p>Shortlist and Qualify</p>',
                text: 'Shortlist and Qualify',
              },
            },
            {
              content: {
                html: '<p>Apply on our digital portal. Our team manages approval with the lender</p>',
                text: 'Apply on our digital portal. Our team manages approval with the lender',
              },
              icon: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/2ef6cb25-a2c1-468d-9f6b-11ac48c5ece9_icons8-cv.svg',
              },
              image: {
                url:
                  'https://images.prismic.io/youbroker/4930f0bd-6422-43ac-9b52-59d7dcb181c9_apply-new.png?auto=compress%2Cformat&rect=0%2C0%2C2308%2C2032&w=2000&h=1761',
              },
              title: {
                html: '<p>Easily apply</p>',
                text: 'Easily apply',
              },
            },
            {
              content: {
                html: '<p>Track the completion and settlement of your loan online from start to finish</p>',
                text: 'Track the completion and settlement of your loan online from start to finish',
              },
              icon: {
                url:
                  'https://youbroker.cdn.prismic.io/youbroker/090c8cc5-15de-4cfe-bd2a-6019df8961c8_icons8-clock_checked.svg',
              },
              image: {
                url:
                  'https://images.prismic.io/youbroker/40082d6b-b8cd-424c-80f1-de3bfb9193df_choose-new.png?auto=compress%2Cformat&rect=0%2C0%2C2308%2C2032&w=2000&h=1761',
              },
              title: {
                html: '<p>Track and Settle</p>',
                text: 'Track and Settle',
              },
            },
          ],
        },
      },
    },
  ],
};

export default fable;
