const fable = {
  name: 'Two Cols Feature List',
  variations: [
    {
      title: 'Image Left',
      props: {
        data: {
          primary: {
            content: {
              html: '',
              text: '',
            },
            image: {
              url: 'https://images.prismic.io/youbroker/de065f2b-929b-4668-b8a0-364dd8481a81_Hands+Illustrations_44.png?auto=compress,format&rect=0,0,3500,2625&w=2000&h=1500',
            },
            image_left: true,
            title: {
              html: '<h1>Choose from a wide range of loan options, tailored to your individual needs.</h1>',
              text: 'Choose from a wide range of loan options, tailored to your individual needs.',
            },
          },
          items: [
            {
              content: {
                text: 'Choose from over 2,500 different home loan options',
                html: '<p>Choose from over 2,500 different home loan options</p>',
              },
              icon: {
                url:
                  'https://youbroker.cdn.prismic.io/youbroker/feac56ff-8de3-4d20-9548-437b76e90405_icons8-choice.svg',
              },
              title: {
                html: '<p>Fast, transparent quotes from the best lenders</p>',
                text: 'Fast, transparent quotes from the best lenders',
              },
            },
            {
              content: {
                text:
                  "We don't have any sales staff. If you can't find what you like, just ask us to negotiate on your behalf",
                html:
                  '<p>We don&#39;t have any sales staff. If you can&#39;t find what you like, just ask us to negotiate on your behalf</p>',
              },
              icon: {
                url:
                  'https://youbroker.cdn.prismic.io/youbroker/dde667a8-623a-4667-a442-5f8f0c1ea8a4_icons8-helping_hand.svg',
              },
              title: {
                html: '<p>Get clarity on your Borrowing Scenario and all the costs and rates</p>',
                text: 'Get clarity on your Borrowing Scenario and all the costs and rates',
              },
            },
            {
              content: {
                text: 'Compare all options on our secure website so that the only people who see your details are you!',
                html:
                  '<p>Compare all options on our secure website so that the only people who see your details are you!</p>',
              },
              icon: {
                url:
                  'https://youbroker.cdn.prismic.io/youbroker/77153c05-1668-472b-a34c-9911530d23e0_icons8-check_lock.svg',
              },
              title: {
                html: '<p>Secure and confidential</p>',
                text: 'Secure and confidential',
              },
            },
            {
              content: {
                text: 'Settled faster. Faster loan settlements mean more money in your pocket.',
                html: '<p>Settled faster. Faster loan settlements mean more money in your pocket.</p>',
              },
              icon: {
                url:
                  'https://youbroker.cdn.prismic.io/youbroker/2c6c8856-8a6e-47d5-80ec-e296a11860af_icons8-running_rabbit.svg',
              },
              title: {
                html: '<p>Easy and fast application process:</p>',
                text: 'Easy and fast application process:',
              },
            },
          ],
        },
      },
    },
    {
      title: 'Image Right',
      props: {
        data: {
          primary: {
            content: {
              html: '',
              text: '',
            },
            image: {
              url: 'https://images.prismic.io/youbroker/de065f2b-929b-4668-b8a0-364dd8481a81_Hands+Illustrations_44.png?auto=compress,format&rect=0,0,3500,2625&w=2000&h=1500',
            },
            image_left: false,
            title: {
              html: '<h1>Choose from a wide range of loan options, tailored to your individual needs.</h1>',
              text: 'Choose from a wide range of loan options, tailored to your individual needs.',
            },
          },
          items: [
            {
              content: {
                text: 'Choose from over 2,500 different home loan options',
                html: '<p>Choose from over 2,500 different home loan options</p>',
              },
              icon: {
                url:
                  'https://youbroker.cdn.prismic.io/youbroker/feac56ff-8de3-4d20-9548-437b76e90405_icons8-choice.svg',
              },
              title: {
                html: '<p>Fast, transparent quotes from the best lenders</p>',
                text: 'Fast, transparent quotes from the best lenders',
              },
            },
            {
              content: {
                text:
                  "We don't have any sales staff. If you can't find what you like, just ask us to negotiate on your behalf",
                html:
                  '<p>We don&#39;t have any sales staff. If you can&#39;t find what you like, just ask us to negotiate on your behalf</p>',
              },
              icon: {
                url:
                  'https://youbroker.cdn.prismic.io/youbroker/dde667a8-623a-4667-a442-5f8f0c1ea8a4_icons8-helping_hand.svg',
              },
              title: {
                html: '<p>Get clarity on your Borrowing Scenario and all the costs and rates</p>',
                text: 'Get clarity on your Borrowing Scenario and all the costs and rates',
              },
            },
            {
              content: {
                text: 'Compare all options on our secure website so that the only people who see your details are you!',
                html:
                  '<p>Compare all options on our secure website so that the only people who see your details are you!</p>',
              },
              icon: {
                url:
                  'https://youbroker.cdn.prismic.io/youbroker/77153c05-1668-472b-a34c-9911530d23e0_icons8-check_lock.svg',
              },
              title: {
                html: '<p>Secure and confidential</p>',
                text: 'Secure and confidential',
              },
            },
            {
              content: {
                text: 'Settled faster. Faster loan settlements mean more money in your pocket.',
                html: '<p>Settled faster. Faster loan settlements mean more money in your pocket.</p>',
              },
              icon: {
                url:
                  'https://youbroker.cdn.prismic.io/youbroker/2c6c8856-8a6e-47d5-80ec-e296a11860af_icons8-running_rabbit.svg',
              },
              title: {
                html: '<p>Easy and fast application process:</p>',
                text: 'Easy and fast application process:',
              },
            },
          ],
        },
      },
    },
  ],
};

export default fable;
