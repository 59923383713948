const fable = {
  name: 'Icons Text Grid',
  variations: [
    {
      title: 'Has Breakout Image',
      props: {
        data: {
          primary: {
            breakout_text: {
              html: '<p>Get qualified in minutes</p>',
              text: 'Get qualified in minutes',
            },
            content: {
              html: '<p>YouBroker makes it fast and easy for your mortgage pre-approval.</p>',
              text: 'YouBroker makes it fast and easy for your mortgage pre-approval.',
            },
            image: {
              url:
                'https://images.prismic.io/youbroker/ed88f07d-9360-4dbd-97d7-093c17183e8e_couple-1.png?auto=compress%2Cformat&rect=0%2C0%2C870%2C712&w=2000&h=1637',
            },
            title: {
              text: 'Get financed faster.',
              html: '<h1>Get financed faster.</h1>',
            },
          },
          items: [
            {
              content: {
                html:
                  '<p>See the latest mortgage rates and the features of home loans you&#39;re interested in with our simple, user-friendly comparison calculator.</p>',
                text:
                  "See the latest mortgage rates and the features of home loans you're interested in with our simple, user-friendly comparison calculator.",
              },
              icon: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/8e25dc31-a8f9-4a4d-bdf0-9d0fe486efdd_document.svg',
              },
              title: {
                html: '<p>Compare and filter</p>',
                text: 'Compare and filter',
              },
            },
            {
              content: {
                html:
                  '<p>Pick your preferred loans and see if you qualify.<br />Our expert team validates your choice and borrowing</p>',
                text:
                  'Pick your preferred loans and see if you qualify.\nOur expert team validates your choice and borrowing',
              },
              icon: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/b7536fd4-e161-45ac-8ed2-4617d5edd4d8_shortlist.svg',
              },
              title: {
                html: '<p>Shortlist and Qualify</p>',
                text: 'Shortlist and Qualify',
              },
            },
            {
              content: {
                html: '<p>Apply on our digital portal.<br />Our team manages approval with the lender</p>',
                text: 'Apply on our digital portal.\nOur team manages approval with the lender',
              },
              icon: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/bdfe33cf-e172-4349-a80a-d6e961a4866b_letter.svg',
              },
              title: {
                html: '<p>Easily apply</p>',
                text: 'Easily apply',
              },
            },
            {
              content: {
                html: '<p>Track the completion and settlement of your loan online from start to finish</p>',
                text: 'Track the completion and settlement of your loan online from start to finish',
              },
              icon: {
                url:
                  'https://youbroker.cdn.prismic.io/youbroker/13740123-cc50-4216-ba82-de4f9ac65ee2_track-and-settle.svg',
              },
              title: {
                html: '<p>Track and Settle</p>',
                text: 'Track and Settle',
              },
            },
          ],
        },
      },
    },
    {
      title: 'No Breakout Box',
      props: {
        data: {
          primary: {
            image: {
              url:
                'https://images.prismic.io/youbroker/ed88f07d-9360-4dbd-97d7-093c17183e8e_couple-1.png?auto=compress%2Cformat&rect=0%2C0%2C870%2C712&w=2000&h=1637',
            },
            content: {
              html: '<p>YouBroker makes it fast and easy for your mortgage pre-approval.</p>',
              text: 'YouBroker makes it fast and easy for your mortgage pre-approval.',
            },
            title: {
              text: 'Get financed faster.',
              html: '<h1>Get financed faster.</h1>',
            },
          },
          items: [
            {
              content: {
                html:
                  '<p>See the latest mortgage rates and the features of home loans you&#39;re interested in with our simple, user-friendly comparison calculator.</p>',
                text:
                  "See the latest mortgage rates and the features of home loans you're interested in with our simple, user-friendly comparison calculator.",
              },
              icon: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/8e25dc31-a8f9-4a4d-bdf0-9d0fe486efdd_document.svg',
              },
              title: {
                html: '<p>Compare and filter</p>',
                text: 'Compare and filter',
              },
            },
            {
              content: {
                html:
                  '<p>Pick your preferred loans and see if you qualify.<br />Our expert team validates your choice and borrowing</p>',
                text:
                  'Pick your preferred loans and see if you qualify.\nOur expert team validates your choice and borrowing',
              },
              icon: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/b7536fd4-e161-45ac-8ed2-4617d5edd4d8_shortlist.svg',
              },
              title: {
                html: '<p>Shortlist and Qualify</p>',
                text: 'Shortlist and Qualify',
              },
            },
            {
              content: {
                html: '<p>Apply on our digital portal.<br />Our team manages approval with the lender</p>',
                text: 'Apply on our digital portal.\nOur team manages approval with the lender',
              },
              icon: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/bdfe33cf-e172-4349-a80a-d6e961a4866b_letter.svg',
              },
              title: {
                html: '<p>Easily apply</p>',
                text: 'Easily apply',
              },
            },
            {
              content: {
                html: '<p>Track the completion and settlement of your loan online from start to finish</p>',
                text: 'Track the completion and settlement of your loan online from start to finish',
              },
              icon: {
                url:
                  'https://youbroker.cdn.prismic.io/youbroker/13740123-cc50-4216-ba82-de4f9ac65ee2_track-and-settle.svg',
              },
              title: {
                html: '<p>Track and Settle</p>',
                text: 'Track and Settle',
              },
            },
          ],
        },
      },
    },
  ],
};

export default fable;
