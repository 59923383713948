const fable = {
  name: 'Two Cols Text',
  variations: [
    {
      title: 'Default',
      props: {
        data: {
          primary: {
            title: {
              html: '',
              text: '',
            },
          },
          items: [
            {
              content: {
                html:
                  '<p>At YouBroker, you’ll have people by your side the whole way. We’re here to take the hassle out of home loans and give you hours of your life back.</p><p>Our team of amazing brokers is here to find you the best mortgage. And with our complete services, you’ll have the support of our surveyor and conveyancer partners to help you along the journey.</p>',
                text:
                  'At YouBroker, you’ll have people by your side the whole way. We’re here to take the hassle out of home loans and give you hours of your life back. Our team of amazing brokers is here to find you the best mortgage. And with our complete services, you’ll have the support of our surveyor and conveyancer partners to help you along the journey.',
              },
              cta_link: {
                url: '/fable/',
              },
              cta_text: {
                html: '<p>Get in touch</p>',
                text: 'Get in touch',
              },
              icon: {
                url:
                  'https://youbroker.cdn.prismic.io/youbroker/6febb610-8b73-4c6a-a5b0-98cccb049a6c_icons8-ask_question.svg',
              },
              title: {
                html: '<p>Our experts</p>',
                text: 'Our experts',
              },
            },
            {
              content: {
                html:
                  '<p>We&#39;re powered by technology to give you the most accurate information online so you can compare home loans, qualify on your own terms, and apply in just a few minutes—all from your computer or smartphone. Easy, simple, and secure.</p><p>We don’t book travel at the travel agents office any longer, and we believe the same applies when it comes to home loans. </p><p>Why wait for a bank or broker to come back to you, when you can progress your application on your own terms, at your own pace?</p>',
                text:
                  "We're powered by technology to give you the most accurate information online so you can compare home loans, qualify on your own terms, and apply in just a few minutes—all from your computer or smartphone. Easy, simple, and secure. We don’t book travel at the travel agents office any longer, and we believe the same applies when it comes to home loans.  Why wait for a bank or broker to come back to you, when you can progress your application on your own terms, at your own pace?",
              },
              cta_link: {
                url: '/fable/',
              },
              cta_text: {
                html: '<p>About Us</p>',
                text: 'About Us',
              },
              icon: {
                url: 'https://youbroker.cdn.prismic.io/youbroker/6ace106a-52d9-4371-8ea6-8410fe381855_icons8-trust.svg',
              },
              title: {
                html: '<p>Our ethos</p>',
                text: 'Our ethos',
              },
            },
          ],
        },
      },
    },
  ],
};

export default fable;
