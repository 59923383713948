const fable = {
  name: 'Blog Feed',
  variations: [
    {
      title: 'Default',
      props: {
        data: {
          primary: {
            variant: 'Light Blue Background',
            title_text: {
              text: 'From our blog',
            },
          },
        },
      },
    },
  ],
};

export default fable;
