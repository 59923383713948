const fable = {
  name: 'Two Cols Image Text',
  variations: [
    {
      title: 'Image Not Contained',
      props: {
        data: {
          primary: {
            contain_image: false,
            content: {
              html:
                '<p>We are looking for people to join our team. It&#39;s early days but an exciting stage in which you can help shape the future of home loans.</p>',
              text:
                "We are looking for people to join our team. It's early days but an exciting stage in which you can help shape the future of home loans.",
            },
            cta_option: 'Email Us',
            image: {
              url:
                'https://images.prismic.io/youbroker/b4be08e2-f4f5-4e0b-9137-ccde037294c3_photo-1522202176988-66273c2fd55f.jpeg?auto=compress%2Cformat&rect=0%2C0%2C2851%2C1900&w=2000&h=1333',
            },
            primary_title: {
              html: '<h1>Want to help us</h1>',
              text: 'Want to help us',
            },
            secondary_title: {
              html: '<h2>transform lending?</h2>',
              text: 'transform lending?',
            },
          },
        },
      },
    },
    {
      title: 'Image Contained',
      props: {
        data: {
          primary: {
            contain_image: true,
            content: {
              html:
                '<p>We are looking for people to join our team. It&#39;s early days but an exciting stage in which you can help shape the future of home loans.</p>',
              text:
                "We are looking for people to join our team. It's early days but an exciting stage in which you can help shape the future of home loans.",
            },
            cta_option: 'Email Us',
            image: {
              url:
                'http://localhost:8000/static/f66c737b9cce80e586360072264ef5c9/c6127/de065f2b-929b-4668-b8a0-364dd8481a81_Hands%2BIllustrations_44.webp',
            },
            primary_title: {
              html: '<h1>Want to help us</h1>',
              text: 'Want to help us',
            },
            secondary_title: {
              html: '<h2>transform lending?</h2>',
              text: 'transform lending?',
            },
          },
        },
      },
    },
  ],
};

export default fable;
